import axios from "axios"
import pako from "pako"

import {
  SEND_TO_SHEET_FAIL,
  SEND_TO_SHEET_REQUEST,
  SEND_TO_SHEET_SUCCESS,
  SLREPORTSLIST_FAIL,
  SLREPORTSLIST_REQUEST,
  SLREPORTSLIST_SUCCESS,
  SLREPORT_FAIL,
  SLREPORT_REQUEST,
  SLREPORT_SUCCESS,
  TRACK_FAIL,
  TRACK_REQUEST,
  TRACK_SUCCESS
} from "../constants/SLReportConstants"
import {toastMessages} from "./toastMessages"
import Papa from "papaparse"
import {mkConfig, generateCsv, download} from "export-to-csv" //or use your library of choice here

// Example function converting data to CSV
function exportToCSV(dataArray) {
  try {
    const csvString = Papa.unparse(dataArray)
    // Further processing like creating a Blob, etc.
  } catch (error) {
    console.error("Error converting to CSV:", error)
  }
}

const csvConfig = mkConfig({
  fieldSeparator: ",",
  decimalSeparator: ".",
  useKeysAsHeaders: true
})

function handleExportData(data) {
  const csv = generateCsv(csvConfig)(data)
  download(csvConfig)(csv)
}

const base_url = process.env.REACT_APP_API_BASE_URL

export const getReportsList = (filter) => async (dispatch, getState) => {
  try {
    dispatch({
      type: SLREPORTSLIST_REQUEST
    })

    const {
      userLogin: {userInfo}
    } = getState()

    const config = {
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${userInfo.token}`
      }
    }
    const {data} = await axios.get(`${base_url}/reports/reports-list`, config)

    dispatch({
      type: SLREPORTSLIST_SUCCESS,
      payload: data
    })
  } catch (error) {
    console.log(error)
    // toastMessages(0, "Connection Error")
    window.location.href = "/login"
    dispatch({
      type: SLREPORTSLIST_FAIL,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.message
    })
  }
}

export const getSLReport = (filter, reports) => async (dispatch, getState) => {
  try {
    dispatch({
      type: SLREPORT_REQUEST
    })
    let jsonData

    try {
      // Step 1: Check if the URL contains a .gz file extension
      if (filter.url.split("?")[0].endsWith(".gz")) {
        // Step 2: Download the .gz file
        const {data} = await axios.get(filter.url, {
          responseType: "arraybuffer" // Important to get the file as a buffer
        })

        const gzFileBuffer = data

        // Step 3: Decompress the .gz file using pako
        const decompressedData = await pako.ungzip(gzFileBuffer, {to: "string"})

        // Step 4: Parse the JSON data
        jsonData = await JSON.parse(decompressedData)
      } else {
        const {data} = await axios.get(filter.url, {})
        // Step 4: Parse the JSON data
        jsonData = data
      }
    } catch (error) {
      window.alert(`Failed to fetch or parse the .gz file: ${error.message}`)
    }

    if (filter.customFilter) {
      const filteredArray = jsonData.filter((record) =>
        filter.customFilter.list.includes(record[filter.customFilter.parameter])
      )
      jsonData = filteredArray
    }
    console.log(jsonData)

    reports[filter.name] = jsonData.reverse()

    if (filter.name == "billing_hist") {
      const currentMonth = new Date().getMonth() + 1 // Get current month (1-12)
      const currentYear = new Date().getFullYear() // Get current year

      const currentMonthAmount = await jsonData
        .filter((item) => {
          const itemDate = new Date(item.date)
          return (
            itemDate.getMonth() + 1 === currentMonth &&
            itemDate.getFullYear() === currentYear
          )
        })
        .reduce((total, item) => total + parseFloat(item.amount), 0)

      const lastMonthAmount = await jsonData
        .filter((item) => {
          const itemDate = new Date(item.date)
          return (
            itemDate.getMonth() + 1 === currentMonth - 1 &&
            itemDate.getFullYear() === currentYear
          )
        })
        .reduce((total, item) => total + parseFloat(item.amount), 0)
      reports["lastMonth"] = Math.round(lastMonthAmount)
      reports["currentMonth"] = Math.round(currentMonthAmount)
    }
    dispatch({
      type: SLREPORT_SUCCESS,
      payload: reports
    })
  } catch (error) {
    // toastMessages(0, "Connection Error")
    dispatch({
      type: SLREPORT_FAIL,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.message
    })
  }
}

export const sendToSheet = (payload) => async (dispatch, getState) => {
  try {
    dispatch({
      type: SEND_TO_SHEET_REQUEST
    })

    const {
      userLogin: {userInfo}
    } = getState()

    let csvString
    // Convert the array to a CSV string
    try {
      csvString = Papa.unparse(payload, {header: true})
    } catch (error) {
      console.log(error)
    }

    // Create a Blob from the CSV string
    const blob = new Blob([csvString], {type: "text/csv"})
    console.log("test3")

    // Create FormData and append the Blob as a file
    const formData = new FormData()
    formData.append("file", blob, "data.csv")
    console.log("test4")

    // Set the appropriate headers, FormData will set 'Content-Type' to 'multipart/form-data'
    const config = {
      headers: {
        // "Content-type": "application/json",
        Authorization: `Bearer ${userInfo.token}`
      }
    }

    const {data} = await axios.post(
      `${base_url}/reports/send-gs-file`,
      formData,
      config
    )

    dispatch({
      type: SEND_TO_SHEET_SUCCESS
    })
    window.alert(data.message)
    // dispatch(toastMessages(1, "Updated Successfuly"))
  } catch (error) {
    window.alert(error.response?.data?.message || error.response)
    // dispatch(toastMessages(0, error.response.data.message))
    dispatch({
      type: SEND_TO_SHEET_FAIL,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.message
    })
  }
}

export const track =
  (payload, reportName, reports, oldRecords) => async (dispatch, getState) => {
    try {
      dispatch({
        type: TRACK_REQUEST
      })

      const {
        userLogin: {userInfo}
      } = getState()

      const config = {
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${userInfo.token}`
        }
      }

      const {data} = await axios.post(
        `${base_url}/reports/track`,
        payload,
        config
      )
      let updatedRecords = oldRecords
      await data.map((record) => {
        updatedRecords[record.index]["trackingStatus"] = record.trackingStatus
        console.log(updatedRecords[record.index]["trackingStatus"])
      })
      reports[reportName] = updatedRecords
      dispatch({
        type: SLREPORT_SUCCESS,
        payload: reports
      })

      dispatch({
        type: TRACK_SUCCESS
      })
    } catch (error) {
      // toastMessages(0, "Connection Error")
      dispatch({
        type: TRACK_FAIL,
        payload:
          error.response && error.response.data.detail
            ? error.response.data.detail
            : error.message
      })
    }
  }
