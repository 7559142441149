import {
  SEND_TO_SHEET_FAIL,
  SEND_TO_SHEET_REQUEST,
  SEND_TO_SHEET_SUCCESS,
  SLREPORTSLIST_FAIL,
  SLREPORTSLIST_REQUEST,
  SLREPORTSLIST_SUCCESS,
  SLREPORT_FAIL,
  SLREPORT_REQUEST,
  SLREPORT_SUCCESS,
  TRACK_FAIL,
  TRACK_REQUEST,
  TRACK_SUCCESS
} from "../constants/SLReportConstants"

export const SLReportReducer = (state = {}, action) => {
  switch (action.type) {
    case SLREPORT_REQUEST:
      return {...state, loading: true}

    case SLREPORT_SUCCESS:
      return {...state, loading: false, SLReportsLists: action.payload}

    case SLREPORT_FAIL:
      return {...state, loading: false}

    default:
      return state
  }
}

export const SLReportsListReducer = (state = {}, action) => {
  switch (action.type) {
    case SLREPORTSLIST_REQUEST:
      return {...state, loading: true}

    case SLREPORTSLIST_SUCCESS:
      return {...state, loading: false, reportsList: action.payload}

    case SLREPORTSLIST_FAIL:
      return {...state, loading: false}

    default:
      return state
  }
}

export const sendToSheetReducer = (state = {}, action) => {
  switch (action.type) {
    case SEND_TO_SHEET_REQUEST:
      return {...state, loading: true}

    case SEND_TO_SHEET_SUCCESS:
      return {...state, loading: false}

    case SEND_TO_SHEET_FAIL:
      return {...state, loading: false}

    default:
      return state
  }
}

export const trackReducer = (state = {}, action) => {
  switch (action.type) {
    case TRACK_REQUEST:
      return {...state, loading: true}

    case TRACK_SUCCESS:
      return {...state, loading: false, reportsList: action.payload}

    case TRACK_FAIL:
      return {...state, loading: false}

    default:
      return state
  }
}
