import {createStore, combineReducers, applyMiddleware} from "redux"
import thunk from "redux-thunk" // Importing 'thunk' directly from redux-thunk
import {composeWithDevTools} from "redux-devtools-extension"

import {
  SLReportReducer,
  SLReportsListReducer,
  sendToSheetReducer,
  trackReducer
} from "./reducers/SLReportReducers"
import {userLoginReducer} from "./reducers/userReducers"
import {columnReducer} from "./reducers/columnsReducers"
import {successAlertReducer} from "./reducers/successReducers"
import {errorAlertReducer} from "./reducers/errorReducers"

const reducer = combineReducers({
  errorAlert: errorAlertReducer,
  successAlert: successAlertReducer,
  userLogin: userLoginReducer,
  SLReportsList: SLReportsListReducer,
  SLReport: SLReportReducer,
  column: columnReducer,
  sendToGoogleSheet: sendToSheetReducer,
  labelToTrack: trackReducer
})

const userInfoFromStorage = localStorage.getItem("userInfo")
  ? JSON.parse(localStorage.getItem("userInfo"))
  : null

const initialState = {
  userLogin: {userInfo: userInfoFromStorage}
}

const middleware = [thunk]

const store = createStore(
  reducer,
  initialState,
  composeWithDevTools(applyMiddleware(...middleware))
)

export default store
