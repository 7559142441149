import styled from "styled-components" // Import styled from styled-components

const ColoredCell = styled.div`
  color: ${(props) => props.color}; // Dynamically set color based on props
`

export const LabelsColumns = [
  {
    accessorKey: "ORDER #",
    header: "ORDER #",
    filterVariant: "text" // default
  },
  {
    accessorKey: "Tracking NO",
    header: "Tracking NO",
    filterVariant: "text", // default
    enableClickToCopy: true
  },
  {
    accessorKey: "trackingStatus",
    header: "trackingStatus",
    filterVariant: "text", // default
    grow: false, //don't allow this column to grow to fill in remaining space - new in v2.8
    size: 50 //small column
  },
  {
    accessorKey: "DOMAIN",
    header: "DOMAIN",
    filterVariant: "text" // default
  },
  {
    accessorKey: "EMAIL",
    header: "EMAIL",
    filterVariant: "text" // default
  },
  {
    accessorFn: (originalRow) => new Date(originalRow.DATE), //convert to date for sorting and filtering
    header: "DATE",
    filterVariant: "date-range",
    Cell: ({cell}) => cell.getValue().toLocaleDateString()
  },
  {
    accessorKey: "FROM_first_name",
    header: "FROM_first_name",
    filterVariant: "text" // default
  },
  {
    accessorKey: "FROM_last_name",
    header: "FROM_last_name",
    filterVariant: "text", // default
    enableHiding: false
  },
  {
    accessorKey: "FROM_address",
    header: "FROM_address",
    filterVariant: "text" // default
  },
  {
    accessorKey: "FROM_address2",
    header: "FROM_address2",
    filterVariant: "text" // default
  },
  {
    accessorKey: "FROM_city",
    header: "FROM_city",
    filterVariant: "text" // default
  },
  {
    accessorKey: "FROM_zip_postal_code",
    header: "FROM_zip_postal_code",
    filterVariant: "text" // default
  },
  {
    accessorKey: "FROM_abbreviation",
    header: "FROM_abbreviation",
    filterVariant: "text" // default
  },
  {
    accessorKey: "TO_first_name",
    header: "TO_first_name",
    filterVariant: "text" // default
  },
  {
    accessorKey: "TO_last_name",
    header: "TO_last_name",
    filterVariant: "text" // default
  },
  {
    accessorKey: "TO_address",
    header: "TO_address",
    filterVariant: "text" // default
  },
  {
    accessorKey: "TO_address2",
    header: "TO_address2",
    filterVariant: "text" // default
  },
  {
    accessorKey: "TO_city",
    header: "TO_city",
    filterVariant: "text" // default
  },
  {
    accessorKey: "TO_zip_postal_code",
    header: "TO_zip_postal_code",
    filterVariant: "text" // default
  },
  {
    accessorKey: "TO_abbreviation",
    header: "TO_abbreviation",
    filterVariant: "text" // default
  },
  {
    accessorKey: "Shipping Service",
    header: "Shipping Service",
    filterVariant: "text" // default
  },
  {
    accessorKey: "Pounds",
    header: "Pounds",
    filterVariant: "text" // default
  },
  {
    accessorKey: "Ounces",
    header: "Ounces",
    filterVariant: "text" // default
  },
  {
    accessorKey: "L",
    header: "L",
    filterVariant: "text" // default
  },
  {
    accessorKey: "W",
    header: "W",
    filterVariant: "text" // default
  },
  {
    accessorKey: "H",
    header: "H",
    filterVariant: "text" // default
  },
  {
    accessorKey: "Item-sku",
    header: "Item-sku",
    filterVariant: "text" // default
  },
  {
    accessorKey: "phone num1",
    header: "phone num1",
    filterVariant: "text" // default
  },
  {
    accessorKey: "phone num2",
    header: "phone num2",
    filterVariant: "text" // default
  },
  {
    accessorKey: "size",
    header: "size",
    filterVariant: "text" // default
  },
  {
    accessorKey: "order num (Client)",
    header: "order num (Client)",
    filterVariant: "text" // default
  }
]

export const UsersColumns = [
  {
    accessorKey: "email",
    header: "email",
    filterVariant: "text", // default
    Cell: ({row}) => {
      // Assume the other cell's accessorKey is 'status'
      const statusValue = row.getValue("has_bill")

      // Apply bold style if statusValue is true, otherwise normal
      const textStyle = statusValue === "yes" ? {color: "green"} : {}

      // Render the cell with conditional styling
      return <div style={textStyle}>{row.getValue("email")}</div>
    }
  },
  {
    accessorKey: "has_bill",
    header: "has_bill",
    filterVariant: "text" // default
  },
  {
    accessorKey: "Balance",
    header: "Balance",
    filterVariant: "text" // default
  },
  {
    accessorKey: "pass_new",
    header: "pass_new",
    filterVariant: "text", // default
    enableClickToCopy: true
  },
  {
    accessorKey: "Wallet",
    header: "Wallet",
    filterVariant: "text", // default
    enableClickToCopy: true
  },
  {
    accessorKey: "domain",
    header: "domain",
    filterVariant: "text" // default
  },
  {
    accessorKey: "uid",
    header: "uid",
    filterVariant: "text" // default
  },
  {
    accessorFn: (originalRow) => new Date(originalRow["last_sign_in"]), //convert to date for sorting and filtering
    id: "last_sign_in",
    header: "last_sign_in",
    filterVariant: "datetime",
    Cell: ({cell}) => cell.getValue().toLocaleDateString() // convert back to string for display
  },
  {
    accessorFn: (originalRow) => new Date(originalRow["creation_date"]), //convert to date for sorting and filtering
    id: "creation_date",
    header: "creation_date",
    filterVariant: "datetime",
    Cell: ({cell}) => cell.getValue().toLocaleDateString() // convert back to string for display
  }
]

export const BillsColumns = [
  {
    accessorKey: "email",
    header: "email",
    filterVariant: "text" // default
  },

  {
    accessorKey: "amount",
    header: "amount",
    filterVariant: "text" // default
  },

  {
    accessorKey: "orderID",
    header: "orderID",
    filterVariant: "text" // default
  },
  {
    accessorFn: (originalRow) => new Date(originalRow["date"]), //convert to date for sorting and filtering
    id: "date",
    header: "date",
    filterVariant: "datetime",
    Cell: ({cell}) => cell.getValue().toLocaleDateString() // convert back to string for display
  }
]

export const DailyAnalyzerColumns = [
  {
    accessorKey: "ORDER #",
    header: "ORDER #",
    filterVariant: "text" // default
  },
  {
    accessorKey: "Tracking NO",
    header: "Tracking NO",
    filterVariant: "text", // default
    enableClickToCopy: true
  },
  {
    accessorKey: "urgency",
    header: "urgency",
    filterVariant: "text", // default
    Cell: ({cell}) => {
      let color
      switch (cell.getValue()) {
        case "High":
          color = "red"
          break
        case "Medium":
          color = "orange"
          break
        case "Low":
          color = "green"
          break
        default:
          color = "blue"
      }
      return (
        <ColoredCell style={{color: `${color}`, fontSize: "1rem"}}>
          {cell.getValue()}
        </ColoredCell>
      ) // Use ColoredCell component with dynamic color prop
    }
  },
  {
    accessorKey: "trackingStatus",
    header: "trackingStatus",
    filterVariant: "text" // default
  },
  {
    accessorKey: "status",
    header: "status",
    filterVariant: "text" // default
  },
  {
    accessorKey: "carrier_status",
    header: "carrier_status",
    filterVariant: "text" // default
  },
  {
    accessorKey: "EMAIL",
    header: "EMAIL",
    filterVariant: "text" // default
  },
  {
    accessorFn: (originalRow) => new Date(originalRow["DATE"]), //convert to date for sorting and filtering
    id: "DATE",
    header: "DATE",
    filterVariant: "datetime-range",
    Cell: ({cell}) => cell.getValue().toLocaleDateString() // convert back to string for display
  },
  {
    accessorFn: (originalRow) => new Date(originalRow["Time of Update"]), //convert to date for sorting and filtering
    id: "Time of Update",
    header: "Time of Update",
    filterVariant: "datetime-range",
    Cell: ({cell}) => cell.getValue().toLocaleDateString() // convert back to string for display
  },
  {
    accessorKey: "FROM_first_name",
    header: "FROM_first_name",
    filterVariant: "text" // default
  },
  {
    accessorKey: "FROM_last_name",
    header: "FROM_last_name",
    filterVariant: "text" // default
  },
  {
    accessorKey: "FROM_address",
    header: "FROM_address",
    filterVariant: "text" // default
  },
  {
    accessorKey: "FROM_address2",
    header: "FROM_address2",
    filterVariant: "text" // default
  },
  {
    accessorKey: "FROM_city",
    header: "FROM_city",
    filterVariant: "text" // default
  },
  {
    accessorKey: "FROM_zip_postal_code",
    header: "FROM_zip_postal_code",
    filterVariant: "text" // default
  },
  {
    accessorKey: "FROM_abbreviation",
    header: "FROM_abbreviation",
    filterVariant: "text" // default
  },

  {
    accessorKey: "TO_first_name",
    header: "TO_first_name",
    filterVariant: "text" // default
  },
  {
    accessorKey: "TO_last_name",
    header: "TO_last_name",
    filterVariant: "text" // default
  },
  {
    accessorKey: "TO_address",
    header: "TO_address",
    filterVariant: "text" // default
  },
  {
    accessorKey: "TO_address2",
    header: "TO_address2",
    filterVariant: "text" // default
  },
  {
    accessorKey: "TO_city",
    header: "TO_city",
    filterVariant: "text" // default
  },
  {
    accessorKey: "TO_zip_postal_code",
    header: "TO_zip_postal_code",
    filterVariant: "text" // default
  },
  {
    accessorKey: "TO_abbreviation",
    header: "TO_abbreviation",
    filterVariant: "text" // default
  },

  {
    accessorKey: "Shipping Service",
    header: "Shipping Service",
    filterVariant: "text" // default
  },
  {
    accessorKey: "Pounds",
    header: "Pounds",
    filterVariant: "text" // default
  },

  {
    accessorKey: "Ounces",
    header: "Ounces",
    filterVariant: "text" // default
  },
  {
    accessorKey: "L",
    header: "L",
    filterVariant: "text" // default
  },
  {
    accessorKey: "W",
    header: "W",
    filterVariant: "text" // default
  },
  {
    accessorKey: "H",
    header: "H",
    filterVariant: "text" // default
  },
  {
    accessorKey: "Item-sku",
    header: "Item-sku",
    filterVariant: "text" // default
  },
  {
    accessorKey: "phone num1",
    header: "phone num1",
    filterVariant: "text" // default
  },
  {
    accessorKey: "phone num2",
    header: "phone num2",
    filterVariant: "text" // default
  },
  {
    accessorKey: "size",
    header: "size",
    filterVariant: "text" // default
  },
  {
    accessorKey: "DOMAIN",
    header: "DOMAIN",
    filterVariant: "text" // default
  },

  {
    accessorKey: "order num (Client)",
    header: "order num (Client)",
    filterVariant: "text" // default
  },

  {
    accessorKey: "Last State",
    header: "Last State",
    filterVariant: "text" // default
  },
  {
    accessorKey: "Last City",
    header: "Last City",
    filterVariant: "text" // default
  },
  {
    accessorKey: "Last ZIP Code",
    header: "Last ZIP Code",
    filterVariant: "text" // default
  },
  {
    accessorKey: "Comment",
    header: "Comment",
    filterVariant: "text" // default
  }
]
