import React from "react"
import {useForm} from "react-hook-form"
import {useDispatch} from "react-redux"
import {login} from "../actions/userActions"

const Login = () => {
  const dispatch = useDispatch()

  const {
    register,
    handleSubmit,
    formState: {errors}
  } = useForm()
  const onSubmit = (data) => dispatch(login(data))

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <input
        type="email"
        placeholder="email"
        {...register("email", {required: true})}
      />

      <input
        type="password"
        placeholder="Password"
        {...register("password", {required: true})}
      />

      <input type="submit" />
    </form>
  )
}

export default Login
