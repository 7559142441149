import React from "react"
import {createTheme, ThemeProvider} from "@mui/material/styles"
import {BrowserRouter, Routes, Route, Link} from "react-router-dom"
import ExampleWithLocalizationProvider from "./screens/ExampleWithLocalizationProvider.js"
import Login from "./screens/Login.js"
import Dashboaard from "./screens/Dashboaard.js"

// Dummy components for demonstration
const Page1 = () => <h2>Page 1 Content</h2>
const Page2 = () => <h2>Page 2 Content</h2>

const theme = createTheme({
  // You can customize your theme here
})

function App() {
  return (
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        {/* <nav>
          <ul>
            <li>
              <Link to="/">Dashboaard</Link>
            </li>
            <li>
              <Link to="/login">login</Link>
            </li>
          </ul>
        </nav> */}
        <Routes>
          <Route path="/" element={<Dashboaard />} />
          <Route path="/login" element={<Login />} />
        </Routes>
      </BrowserRouter>
    </ThemeProvider>
  )
}

export default App
