// Shipping Lable Report Constant
export const SLREPORTSLIST_REQUEST = "SLREPORTSLIST_REQUEST"
export const SLREPORTSLIST_SUCCESS = "SLREPORTSLIST_SUCCESS"
export const SLREPORTSLIST_FAIL = "SLREPORTSLIST_FAIL"

export const SLREPORT_REQUEST = "SLREPORT_REQUEST"
export const SLREPORT_SUCCESS = "SLREPORT_SUCCESS"
export const SLREPORT_FAIL = "SLREPORT_FAIL"

export const SEND_TO_SHEET_REQUEST = "SEND_TO_SHEET_REQUEST"
export const SEND_TO_SHEET_SUCCESS = "SEND_TO_SHEET_SUCCESS"
export const SEND_TO_SHEET_FAIL = "SEND_TO_SHEET_FAIL"

export const TRACK_REQUEST = "TRACK_REQUEST"
export const TRACK_SUCCESS = "TRACK_SUCCESS"
export const TRACK_FAIL = "TRACK_FAIL"
