// Random time today function
const today = () => {
  const today = new Date()
  today.setHours(Math.floor(Math.random() * 24))
  today.setMinutes(Math.floor(Math.random() * 60))
  today.setSeconds(Math.floor(Math.random() * 60))
  return today
}

// Data array
const data = []
// const data = [
//   {
//     isActive: true,
//     name: "Tanner Linsle",
//     hireDate: "2016-02-23T18:25:43.511Z",
//     arrivalTime: "2016-02-23T18:25:43.511Z",
//     departureTime: today(),
//     age: 42,
//     salary: 100000,
//     city: "San Francisco",
//     state: "California"
//   },
//   {
//     isActive: true,
//     name: "Kevin Vandy",
//     hireDate: "2019-02-23T18:21:43.335",
//     arrivalTime: "2019-02-23T18:21:43.335",
//     departureTime: today(),
//     age: 51,
//     salary: 80000,
//     city: "Richmond",
//     state: "Virginia"
//   },
//   // ... Additional data objects
//   {
//     isActive: false,
//     name: "Samuel Jackson",
//     hireDate: "2010-02-23T18:25:43.511Z",
//     arrivalTime: "2010-02-23T18:25:43.511Z",
//     departureTime: today(),
//     age: 27,
//     salary: 90000,
//     city: "New York",
//     state: "New York"
//   }
// ]

// Cities list
const citiesList = [
  "San Francisco",
  "Richmond",
  "Riverside",
  "Los Angeles",
  "Blacksburg",
  "New York"
]

// US states list
const usStateList = [
  "California",
  "Virginia",
  "South Carolina",
  "New York",
  "Texas"
]

// US states list
const traNoList = [
  "92055903332000300143728788",
  "92001903332000300178770254",
  "92055903332000300143743767",
  "92001903332000300178790009",
  "92001903332000300178761757"
]

// Example of how to export in CommonJS style if needed
module.exports = {data, citiesList, traNoList, usStateList}
