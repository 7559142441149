import {SET_COLUMNS} from "../constants/columnConstants"

export const columnReducer = (state = {}, action) => {
  switch (action.type) {
    //   case USER_LOGIN_REQUEST:
    //     return {loading: true}

    case SET_COLUMNS:
      return {definedColumns: action.payload}

    //   case USER_LOGIN_FAIL:
    //     return {loading: false, error: action.payload}

    default:
      return state
  }
}
